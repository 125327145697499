import axios from 'axios';
import { globalEventConsts, globalValueConsts } from './constants'
import { EventBus } from "@/event-bus.js";
import { StatusCodes } from 'http-status-codes';
import router from './router/index.js';


export default {
    callHttpClient({
        method,
        endpoint,
        payload,
        responseType = 'json',
        ignoreErrors = false,
        bearerToken = null
    }) {
        return new Promise((resolve, reject) => {
            // Grab our accessToken from Okta.         
            const accessToken = bearerToken ?? this.getBearerTokenForStorage();

            // Merge payload with analytics data   
            const payloadAndAnalyticsData = Object.assign({}, payload, {
                AppName: "Agency Advantage Dashboard"
            });
            axios({
                    method: method,
                    url: endpoint,
                    data: payloadAndAnalyticsData,
                    crossdomain: true,
                    responseType: responseType,
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                .then(function(response) {
                    if ((response.data && response.data.isSuccess) || (response.status == 200)) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                }, error => {
                    //let statusCode = error.response.status;                    
                    try{
                    let statusCode = error?.response?.status;
                    let message = 'Sorry, something happened on our end. Please try again';
                    if (!ignoreErrors) {
                       switch (statusCode) {
                            case StatusCodes.UNAUTHORIZED:
                               this.createPromiseRejection("Unauthorized, routing back to login page.", error.response, reject, true, false);
                                break;
                            case StatusCodes.BAD_REQUEST:
                                this.createPromiseRejection(error?.response?.data, error.response, reject, true, false);                               
                                break;
                            default:
                                this.createPromiseRejection(error?.response?.data?.message, error.response, reject, false, true);
                                EventBus.$emit(globalEventConsts.SHOW_ALERT_BANNER, globalValueConsts.ALERT_BANNER_RED, message, dataLayer);
                        }
                    } else {
                        this.createPromiseRejection(error?.response?.data?.message, error.response, reject, false, true);
                        return reject(error);
                    }
                }
                catch(error){}
                });
        })
    },

    IsUserAuthenticated() {
        let token = this.getBearerTokenForStorage();
        return token != '';
    },

    // There has to be a better way to do this, the built in API for okta doesn't work.
    getBearerTokenForStorage() {
        try {
            return JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken;
        } catch (error) {
            return '';
        }
    },

  
    // Creates a promise rejection ----------------------------------------------
    // Message = your return message
    // responseData = The return object from the server
    // isError = Error, not exception (Like if we're handling an error ourselves)
    // isException = Actual exception from the HTTP call
    // --------------------------------------------------------------------------
    createPromiseRejection(message, responseData, reject, isError = false, isException = false) {     
    if (typeof dataLayer != 'undefined' &&
        dataLayer != null) {
            dataLayer.push({
            'event': 'event',
            'category': "agent_global_error",
            'action': router.currentRoute.name,
            'label': responseData.statusText,
            'value': 0
          });
        }                        
        return reject({
            message: message,
            isError: isError,
            isException: isException,
            responseData: responseData
        });
    }
}